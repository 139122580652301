import $ from 'jquery';
window.jQuery = window.$ = $;

import device from 'current-device';

import './app.js';


function checkZero(data){
    if(data.length == 1){
      data = "0" + data;
    }
    return data;
  }

$(function () {

    $('body').on('click', '[data-modal]', function(e) {
        e.preventDefault;
        
        var modalID = '#' + $(this).attr('data-modal');
        console.log(modalID);
        $(modalID).addClass('active');
    });

    $('body').on('click', function() {
        if($('.o-modal.active').length > 0) {
            $('html').css('overflow-y', 'hidden');
    
            $('.o-modal--close, [data-modal-trigger]').on('click', function() {
                $('.o-modal.active').removeClass('active');
            });
        } else {
            $('html').css('overflow-y', 'auto');
        }
    });

    
    $('.o-dropdown').on('click', function() {
        $(this).next('.o-dropdown-list').addClass('active');
    });

    // $(':not([data-dropdown]) *').on('mousedown', function (e) {
    //     if ($("[data-dropdown]").hasClass('active')) {
    //         $("[data-dropdown]").removeClass('active');
    //     }
    // });

    $('.c-content--faq__item-title').on('click', function() {

        $('.c-content--faq__item-title i').removeClass('opened');

        $('.c-content--faq__item-content').slideUp();

        setTimeout(() => {
            if(!$(this).next('.c-content--faq__item-content').is(':visible')) {
                $(this).children('i').addClass('opened');
                $(this).next('.c-content--faq__item-content').slideDown();
            }
        }, 300);
    });

    $(document).on('click', function(e) {
        var target = e.target;
        if (!$(target).is('.o-dropdown') && !$(target).is('.o-dropdown *') && !$(target).is('.o-dropdown-list') && !$(target).parents().is('.o-dropdown-list')) {
            $('.o-dropdown-list').removeClass('active');
        }
      });

    $("#formActions").load("data/initialForm.html");
    $("#validationButtons").load("data/validateForm.html");

    $('body').on('change', '[data-form="isencao"]', function() {
        if($(this).is(':checked')) {
            $("#formActions").load("data/exemptForm.html");
            $('#validationButtons > *').remove();
        } else {
            $("#formActions").load("data/initialForm.html");
            $("#validationButtons").load("data/validateForm.html");
        }
    });

    $('body').on('change', '[data-form="conjuge"]', function() {
        if($(this).is(':checked')) {
            $.get('data/consortForm.html', function(data) {
                $('#uploadFields').append(data);  
              });
        } else {
            $("#formActions").load("data/initialForm.html");
        }
    });

    $('body').on('click', '[data-form="voltar"]', function() {
        $("#formActions").load("data/initialForm.html");
        $("#validationButtons").load("data/validateForm.html");
    });

    $('body').on('change', '.o-form--upload', function(e){
        console.log('yay');
        var now = new Date();
        var day = now.getDate() + "";
        var month = (now.getMonth() + 1) + "";
        var year = now.getFullYear() + "";
        var hour = now.getHours() + "";
        var minutes = now.getMinutes();

        day = checkZero(day);
        month = checkZero(month);
        year = checkZero(year);
        hour = checkZero(hour);
        minutes = checkZero(minutes);

        var time = day + "/" + month + "/" + year + " " + hour + ":" + minutes;

        $(this).parent().siblings('.c-content--files__form-filename').append(e.target.files[0].name);
        $(this).parent().siblings('.c-content--files__form-datetime').append(time);
        
        if(e.target.files[0]) {
            $(this).siblings('.o-btn').text('Substituir');
        }
    });

    $('body').on('click', '[data-form="recuperar-senha"]', function() {
        $("#formLogin").load("data/loginRecoverPass.html");
    });

    $('body').on('click', '[data-form="recuperacao-enviada"]', function() {
        $("#formLogin").load("data/loginMailSent.html");
    });

    

    $('body').on('click', '[data-form="validar-declaracao"]', function(e){
        e.preventDefault;

        $('.o-load').addClass('loading');
        $('html, body').css('overflow', 'hidden');

        setTimeout(function() {
            $('.o-load').removeClass('loading');
            $('html, body').css('overflow', 'auto');
        }, 3000);

        $('.c-content--files__form-status').delay(2800).fadeIn(200);
        $('.c-content--files__form-upload').delay(2800).fadeOut(200);
        $('.js-beforeValidation').delay(2800).fadeOut(200);
        $('.js-validated').delay(2800).fadeIn(200);
        setTimeout(function() {
            $("#validationButtons").load("data/finishForm.html");
        }, 2800);
    });
    

    $('.c-query__btn').on('click', function() {
        $('.c-query__btn.active').removeClass('active');
        $(this).addClass('active');
        
        var filename = $(this).data('pdf');
        var path = '/../../assets/ViewerJS/#../pdf/';
        $('.c-query__content').empty().append('<iframe class="c-query__viewer" src="' + path + filename +'" allowfullscreen webkitallowfullscreen></iframe> ');
    });

    var inputRequired = $('.o-form--input[required]');
    inputRequired.siblings('.o-form--label').append(' *');

    $('.o-form--checkbox, .o-form--input__select').on('change', function() {
        if($(this).is('[data-form-enable-id]') || $(this).find('option[data-form-enable-id]').is(':selected') ) {
            var targetID = $(this).attr('data-form-enable-id') || $(this).find('option:selected').attr('data-form-enable-id'); 
            var textAreaTarget = $('[data-form-enable="' + targetID +'"]');
            if($(textAreaTarget).is(':disabled') == true) {
                textAreaTarget.prop('disabled', false);
            } else {
                textAreaTarget.prop('disabled', true);
            }
        }
    });

    $('.o-form--input__year').attr('max', new Date().getFullYear());

    $('[data-filter="clear"]').on('click', function(e) {
        e.preventDefault();
        var form = $(this).closest('[data-dropdown="filters"] .o-form');

        $(form)[0].reset();
    });
    
    $('th input[type="checkbox"]').on('change', function() {
        if($(this).is(':checked')) {
            $('td input[type="checkbox"]').prop('checked',true);
        } else {
            $('td input[type="checkbox"]').prop('checked', false);
        }
    });

    $(document).on('click','.close',function(){
        $(this).parents('.c-content--files__form-row').remove();
        $('[data-trigger="attachments"]').val("");
    
    });
    
    $('[data-trigger="attachments"]').on('change', function() {

        var now = new Date();
        var day = now.getDate() + "";
        var month = (now.getMonth() + 1) + "";
        var year = now.getFullYear() + "";
        var hour = now.getHours() + "";
        var minutes = now.getMinutes();

        day = checkZero(day);
        month = checkZero(month);
        year = checkZero(year);
        hour = checkZero(hour);
        minutes = checkZero(minutes);

        var time = day + "/" + month + "/" + year + " " + hour + ":" + minutes;

        var filename = this.value;
        var lastIndex = filename.lastIndexOf("\\");
        if (lastIndex >= 0) {
            filename = filename.substring(lastIndex + 1);
        }
        var files = $('[data-trigger="attachments"]')[0].files;
        for (var i = 0; i < files.length; i++) {
            $(".o-upload-files").append('<div class="c-content--files__form-row">'+'<span class="c-content--files__form-filename">'+files[i].name+'</span>'+'<span class="close"><i class="icon-close"></i></span></div></div>');
        }    
    });

    $('.js-notification').append('<span class="notify">Novas</span>');

    $('[data-trigger="support-status"]').on('change', function() {
        if($(this).is(':checked')) {
            $(this).next('[data-button="support-status"]').html('<i class="icon-lock-open"></i>Reabrir solicitação');
            $('.c-content--grid').find('textarea, input[type="file"], button').prop('disabled', true);
            $('.c-content--grid').find('input[type="submit"]').hide();
            $('.c-content--grid').slideUp(300);
            
        } else {
            $(this).next('[data-button="support-status"]').html('<i class="icon-check"></i>Marcar como resolvida');
            $('.c-content--grid').find('textarea, input[type="file"], button').prop('disabled', false);
            $('.c-content--grid').find('input[type="submit"]').show();
            $('.c-content--grid').slideDown(300);
        }
    });

});
